.reportTitle {
    margin-left: 120px;
}

.container {
    position: relative;
    margin: var(--top-margin) auto calc(var(--bottom-margin) + 100px) auto;
    width: 70%;
    color: var(--main-white);
}

.tableHeader tr {
    text-align: left;
    font-size: 1.5rem;
}

.tableBody {
    text-align: left;
}

.tableBody tr {
    cursor: pointer;
}

.tableBody span {
    color: rgb(218, 171, 44);
    padding-right: 0.5rem;
}

.tableBody pre {
    font-family: inherit;
}

.tableBody tr:hover {
    color: rgb(143, 143, 143);
}

.edit-button {
    position: absolute;
    transform: translateX(-80px);   
    margin-top: 10px;
}

