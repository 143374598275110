.wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    padding-top: 50px;
    gap: 30px;
}
.search {
    display: flex;
    justify-content: center;
}
.trialBtn {
    font-size: 1.6rem;
}
.trialBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px 40px;
}

@media screen and (max-width: 768px) {
    .wrapper {
        padding-top: 100px;
    }
}
