.wrapper,
.title {
    display: flex;
    align-items: center;
}
.title {
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    font-weight: 600;
    gap: 5px;
    margin-right: 10px;
}
.inline {
    display: inline;
}
