.results {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 10px;
}
.results table {
    border-collapse: collapse;
}
.list {
    width: 100%;
    border-radius: 10px;
}

.list thead {
    text-align: left;
    border-radius: 0 10px 10px 0;
}
.list td,
.list th {
    border: 1px solid #555;
}
.list th {
    font-size: 1.4rem;
    padding: 20px;
}
.list td {
    padding: 15px 20px;
}
.list td a {
    cursor: pointer;
}
.list td:last-child {
    text-align: center;
}
.list th {
    border: none;
    min-width: 150px;
    font-weight: normal;
    font-size: 1.125rem;
    cursor: pointer;
    background: #555;
}
.list tr {
    background: #333;
}
.inline-button {
    margin: 0;
}
.inline-button svg {
    margin-right: 5px;
}

.edit-button {
    position: absolute;
    left: -50px;
}
.edit-window {
    width: 50%;
    height: 100%;
    background: var(--bright-grey);
    top: 0;
    right: 0;
    position: fixed;
}
.edit-window .grab {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 10px;
    border: 1px solid red;
}
.edit-window .grab:hover {
    cursor: grab;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 10px;
    border: 1px solid red;
}
.edit-wrapper {
    padding: 50px;
    overflow-y: auto;
    width: 100%;
    height: 100%;
}

.wrapper form {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
}

.verified svg {
    height: 100px;
}
