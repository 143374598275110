.news-page {
    width: 50%;
    margin: auto;
}
.news-element {
    padding-bottom: 20px;
}
.news-element h2 {
    margin: 10px 0 5px 0;
    display: inline-block;
}
.news-element span {
    text-align: middle;
    margin: 10px 0 5px;
    line-height: 26pt;
    float: right;
}
.news-title {
    padding-bottom: 10px;
}
.news-title h3,
.news-title h4 {
    width: 100%;
    margin: 0;
}

.extended-info {
    display: none;
    width: 100%;
}
.nonews {
    opacity: 0.7;
    text-align: center;
}
