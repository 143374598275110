.wrapper {
    margin: 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 40px;
    width: 100%;
}
.lists {
    width: 100%;
}
.tabs {
    display: inline-flex;
    border-radius: 10px;
    background: #252525;
}
.tab {
    padding: 15px;
    cursor: pointer;
}
.tab + .tab {
    border-left: 1px solid #494949;
}
.selected {
    color: #51a6d9;
    text-decoration: underline;
}
