.window {
    width: 50%;
    height: 100%;
    background: var(--bright-grey);
    top: 0;
    right: 0;
    position: fixed;
    box-shadow: -5px 0px 8px var(--dark-grey);
}
.window .grab {
    position: absolute;
    display: flex;
    justify-content: center;
    flex-flow: column;
    top: 50px;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 30px;
}
.window .grab:hover {
    cursor: grab;
}
.window .grab svg {
    transform: translateY(-50px);
}
.wrapper {
    padding: 50px;
    overflow-y: auto;
    width: 100%;
    height: 100%;
}
.window > button {
    position: absolute;
}
.window button svg {
    height: 20px;
}

.window button:hover {
    cursor: pointer;
}
