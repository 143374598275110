.wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.info {
    border: 1px solid rgb(51, 51, 51);
    padding: 40px;
    border-radius: 10px;
    display: flex;
    flex-flow: column;
    gap: 20px;
    background: rgb(26, 26, 26);
}
.info h2,
.info p {
    text-align: left;
}
.info h3 {
    margin: 0;
}
.info p:after {
    content: '';
    display: inline-block;
    width: 0px;
}
