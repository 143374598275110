.wrapper {
    width: 50%;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header h3 {
    margin-bottom: 20px;
}
.groups {
    display: flex;
    flex-flow: row wrap;
    gap: 20px;
    margin-bottom: 50px;
}
