.alertBox {
    display: flex;
    align-items: center; 
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ff8181;
    background: #FECBCB;
    color: #181818;
    position: relative;
    margin-bottom: 15px;
    width: 100%;
    height: 56px;
}

.closeButton {
    position: absolute;
    right: 10px;
    top: 55%;
    transform: translateY(-50%);
    border: none;
    background: none;
    color: #5B5B5B;
    cursor: pointer;

}

.icon svg {
    font-size: 36px;
    margin-right: 20px;
    color: #FF5F5F;
}


