* {
    margin: 0;
    padding: 0;
}
.page-title {
    margin: 59px auto 51px 106px;
    font-size: 2.125rem;
    font-weight: 400;
    line-height: 2.25rem;
}
.form {
    width: 732px;
    margin-left: 106px;
    display: flex;
    flex-direction: column;
    gap: 1.375rem;
}
.drag-and-drop-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 58px 40px;
}
.drag-and-drop-area-description {
    margin: 13px 0 13px 0;
    color: var(--grey);
    text-align: center;

    font-size: 1.1875rem;
    font-weight: 400;
    line-height: 1.5rem;
}
.description-bottom-text {
    font-size: 16px;
}
.file-indicator {
    height: 30px;
    margin: 13px 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: var(--grey);
}
.form-controllers-container {
    display: flex;
    margin-left: auto;
    margin-bottom: 50px;
    width: 228px;
    height: 34px;
    gap: 0.5rem;
}
.upload-button,
.save-button {
    margin: 0;
    padding: 0;
    width: 110px;

    font-family: Inter;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1rem;
    letter-spacing: 0.1px;
}
.save-button {
    background-color: rgb(128, 128, 128);
    border: none;
}
.upload-icon {
    padding-right: 5px !important;
}
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    display: flex;
    justify-content: center;
    align-items: center;
}
.button-shape {
    margin: 0 10px;
    text-transform: capitalize;
}
.no-margin {
    margin: 0;
}
