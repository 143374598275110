.wrapper {
    position: relative;
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
    width: 50vw;
}
.search {
    width: 75%;
    position: relative;
    text-align: center;
}
.search input {
    width: 100%;
    font-size: 1.1rem;
    border-radius: 30px;
    margin: 0;
    padding: 15px 50px 15px 50px;
}
.search .icon {
    filter: invert(0.4);
    width: 20px;
    position: absolute;
    top: 16px;
    left: 20px;
}
.cancel {
    cursor: pointer;
    width: 20px;
    position: absolute;
    top: 9px;
    right: 50px;
    bottom: 6px;
    width: 40px;
    border-radius: 25px;
    border: none;
    background: transparent;
    /* filter: drop-shadow(1px 1px 1px #9c9c9c); */
    opacity: 0.5;
}
.submit {
    cursor: pointer;
    width: 20px;
    position: absolute;
    top: 6px;
    right: 8px;
    bottom: 6px;
    width: 40px;
    border-radius: 25px;
    border: none;
    background: var(--main-blue);
    filter: drop-shadow(1px 1px 2px #9c9c9c);
}
.suggestions {
    position: absolute;
    width: 100%;
    top: 80px;
}

@media (max-width: 768px) {
    .wrapper {
        width: 100vw;
    }
}
