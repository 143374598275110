.comment-section {
    /* margin-top: 80px; */
}

.comment-section,
.comments-container {
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.comment-title {
    margin: 0;
    font-size: 2.125rem;
}

.comment-section-prompt {
    margin: 0;
    font-size: 1rem;
}

.nocomments {
    opacity: 0.5;
    max-width: 500px;
    text-align: center;
}
