.default {
    --outline-color: rgb(128, 128, 128);
    --header-bg: rgb(83, 83, 83);
    --cell-bg: rgb(37, 37, 37);
}
.info {
    --outline-color: rgb(22, 152, 231);
    --header-bg: rgb(41, 83, 109);
    --cell-bg: rgb(20, 42, 54);
}
.critical {
    --outline-color: rgb(231, 217, 22);
    --header-bg: rgb(109, 99, 41);
    --cell-bg: rgb(54, 51, 20);
}
.wrapper {
    width: 100%;
    display: flex;
    flex-flow: column;
    border: 1px solid var(--outline-color);
    background: var(--cell-bg);
    border-radius: 10px;
}
.headers {
    background: var(--header-bg);
    display: flex;
    border-radius: 9px 9px 0px 0px;
    border-bottom: 1px solid var(--outline-color);
}
.headerCell {
    padding: 10px 15px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 10px;
}
.headerCell + .headerCell,
.cell + .cell {
    border-left: 1px solid var(--outline-color);
}
.row {
    display: flex;
}
.row + .row {
    border-top: 1px solid var(--outline-color);
}
.cell {
    padding: 10px 15px;
}
