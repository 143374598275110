.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 30;
}

.inline-button {
    margin: 0;
}

.inline-button svg {
    margin-right: 5px;
}

.wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.wrapper form {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin: 30px 0;
}

.grey-btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: var(--grey);
    border: 2px solid var(--grey);
}

.verified svg {
    margin-bottom: 30px;
    height: 60px;
}
