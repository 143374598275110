.wrapper {
    display: flex;
}
.search ul {
    display: flex;
}
.search ul div {
    margin-left: 20px;
}
.all ul p {
    margin-left: 10px;
}
.logentry {
    display: flex;
    gap: 20px;
}
