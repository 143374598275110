.wrapper {
    padding-left: 40px;
}
.section {
    padding: 10px 20px;
    width: 500px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: rgb(37, 37, 37);
    border-radius: 20px;
}
