.wrapper {
    top: 0;
    left: var(--nav-width);
    z-index: 1000;
    position: fixed;
    width: calc(100vw - var(--nav-width));
    background-color: rgb(17 17 17 / 50%);
    backdrop-filter: blur(3px);
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-box-submitted,
.popup-box {
    position: relative;
    /* text-align: center; */
    background: #474747;
    box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 448px;
}
.popup-box-submitted {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    padding: 50px 0;
}
.popup-box-submitted p {
    font-weight: 700;
    font-size: 20px;
}
.popup-box-header {
    height: 58px;
    background: rgba(128, 128, 128, 0.25);
    display: flex;
    align-items: center;
    padding: 0 25px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    position: relative;
}
.popup-box h2 {
    font-weight: 700;
    font-size: 1.25rem;
    color: #ffffff;
    margin: 0;
    padding: 0;
}
.popup-box-body {
    padding: 40px 25px;
}
.add-orgnization-modal .popup-box-body > div:last-of-type {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}
.add-orgnization-modal .popup-box-body > div:last-of-type > div {
    width: calc(50% - 10px);
}
.remind-modal .popup-box-body {
    padding: 20px 25px 30px;
}
.remind-modal .popup-box-body > p {
    text-align: left;
    margin: 0;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 0.25px;
}
.popup-box-footer {
    padding: 13px 25px;
    background: rgba(128, 128, 128, 0.25);
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: space-around;
}
.popup-box-footer button {
    font-family: 'Inter';
    border: none;
    color: #ffffff;
    padding: 9px 16px;
    cursor: pointer;
    min-width: 110px;
    border-radius: 8px;
}

.modal-close {
    cursor: pointer;
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
}
.modal-button-cancel {
    background-color: #808080;
}
.modal-button-danger {
    background-color: #ff8181;
}
.modal-button-regular {
    background-color: #51a6d9;
}
