.infoLabel {
    font-size: 0.85rem;
}

.mristatus {
    display: flex;
    flex-direction: column;
    gap: 50px;
    margin-bottom: 50px;
}

.mristatus select {
    background: var(--main-selectbox);
    color: var(--main-white);
    max-height: 48px;
    margin-bottom: 25px;
}
