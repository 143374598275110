.wrapper {
    padding: 48px;
}
.menu {
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    gap: 20px;
    margin-bottom: 20px;
}
.options {
    flex-grow: 2;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: #252525;
    gap: 20px;
    padding: 12px;
    border-radius: 5px;
}
.search {
    flex-grow: 2;
    min-width: 250px;
    border: 1px solid #808080;
    background: #353535;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    align-items: center;
}
.search input {
    margin: 0;
    padding: 0;
    margin-left: 10px;
    background: transparent;
    border: none;
    color: white;
    width: 100%;
    border-radius: 0;
}
.search span {
    display: flex;
    width: 100%;
}
.addBtn {
    margin: 0;
}
.implant {
    display: flex;
    gap: 24px;
}
.result {
}
.procedure {
    flex-grow: 3;
    position: relative;
    padding: 30px;
    padding-bottom: 100px;
    background: rgb(75, 75, 75);
    border-radius: 10px;
    align-self: start;
    min-height: 945px;
    min-width: 55%;
}
.procedure .loadingwrapper {
    background: rgb(75, 75, 75);
}
.loadingwrapper {
    position: absolute;
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 800px;
}
.save-filter form {
    display: flex;
    flex-flow: row;
    align-items: flex-end;
}
.message {
    font-size: 0.8rem;
    font-weight: 100;
}
.message span {
    color: rgb(218, 171, 44);
}

/* Save Btn */
.saveBtn {
    margin: 0 20px;
}
.saveBtn div {
    display: flex;
    justify-content: center;
}
.saveIcon {
    width: 25px;
}

/* Modal */
.sub-modal h2 {
    text-align: center;
}
.sub-modal p {
    font-size: 0.8rem;
}

.formControll {
    display: flex;
}
.formControll button {
    margin: 0;
}
.formControll input {
    width: 300px;
    height: 44px;
    margin: 0;
}
.message span {
    color: rgb(218, 171, 44);
}
.filterButton {
    display: inline;
}

.filterButton button {
    border: none;
    background: transparent;
    color: white;
    cursor: pointer;
    padding: 0;
}

.activeFilter button {
    font-weight: bold;
    color: var(--main-blue);
    border-bottom: 1px solid var(--main-blue);
}
.filterButton span {
    background: #252525;
    padding: 3px 6px;
    border-radius: 3px;
    font-size: 0.7rem;
    margin: 0 10px 0 5px;
}
.activeFilter span {
    background: var(--main-blue);
}
