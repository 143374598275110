.drag-and-drop {
    display: inline-block;
    position: relative;
    width: 100%;
}

.container {
    margin-top: 10px;
    border: 1px dashed #5A5A5A;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    transition: background-color 0.3s ease;
    position: relative; 
}

.container.dragging-over {
    background-color: var(--main-selectbox);
    border: 1px dashed white;
}

.file-upload-link {
    cursor: pointer;
}

.upload-link {
    color: var(--main-blue);
    text-decoration: underline;
    display: block;
}

.upload-link:hover {
    color: #84C2EF;
}

.drag-and-drop svg {
    width: 36px;
    height: 36px;
}



