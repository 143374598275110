.wrapper {
    display: flex;
    flex-flow: column;
    gap: 10px;
    /* justify-content: center; */
    padding: 10px;
    color: black;
    height: 100%;
}
.title {
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0;
}
.subtitle {
    font-size: 1rem;
    font-weight: 200;
    margin: 0;
}
.infoField {
    font-size: 0.8rem;
    font-weight: 600;
    margin: 0;
}
.infoField span {
    font-weight: 200;
    margin-right: 5px;
}
