.backgroundContainer {
    padding: 30px;
    border-radius: 15px;
    border: 1px solid #2e2e2e;
    background: #181818;
}

.info,
.backgroundContainer,
.conditionals {
    display: flex;
    flex-flow: column;
    width: 100%;
}
.backgroundContainer label,
.column label {
    margin-top: 10px;
}

.backgroundContainer input,
.backgroundContainer select,
.column input,
.column option {
    background: var(--main-selectbox);
    color: var(--main-white);
    max-height: 48px;
    margin-bottom: 25px;
}

.backgroundContainer textarea {
    background: var(--main-selectbox);
    color: var(--main-white);
    line-height: 150%;
    margin-bottom: 25px;
}

.inline-inputs {
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;
    align-items: center;
}

.greyBtn {
    border: 2px solid var(--main-white);
    background: #4e4e4e;
    margin-top: 30px;
    width: 150px;
    color: var(--main-white);
    font-weight: 600;
}

.infoLabel {
    font-size: 0.85rem;
}
