.wrapper {
    margin: 60px auto;
    max-width: 1250px;
    color: #ffffff;
}
.loading {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    color: #c5c5c5;
}
.wrapper h1 {
    color: #ffffff;
    font-weight: 400;
    font-size: 2.125rem;
    border-bottom: 1px solid rgba(128, 128, 128, 0.25);
    padding-bottom: 20px;
}
.wrapper h1,
.wrapper h1 ~ button {
    margin-left: 15px;
}
.wrapper h1 ~ button {
    margin-bottom: 35px;
    margin-top: 0;
}
.wrapper h2 {
    color: #ffffff;
    font-weight: 400;
    font-size: 1.5rem;
    letter-spacing: 0.18px;
    margin-top: 30px;
    margin-bottom: 25px;
}
.wrapper h2,
.noInfoMessage {
    padding-left: 15px;
}
.wrapper table {
    width: 100%;
    border-collapse: collapse;
    /* border-spacing: 0; */
}

.wrapper table th,
.wrapper table td {
    color: #ffffff;
    padding: 0px 20px;
}
.wrapper thead {
    background: rgba(128, 128, 128, 0.25);
    height: 46px;
}
.wrapper thead tr th:first-child {
    border-radius: 5px 0px 0px 5px;
}
.wrapper thead tr th:last-child {
    border-radius: 0px 5px 5px 0px;
}
.wrapper th {
    font-weight: 400;
    font-size: 1rem;
    letter-spacing: 0.15px;
    color: var(--main-white);
}

.wrapper tbody tr td {
    text-align: center;
    height: 46px;
    border-bottom: 1px solid rgba(128, 128, 128, 0.25);
}
.wrapper thead tr th,
.wrapper tbody tr td {
    text-align: left;
    padding-left: 15px;
}
.wrapper tbody tr td {
    cursor: default;
    font-size: 0.875rem;
}

.wrapper tbody tr td button {
    padding: 0;
    margin: 0;
}
.wrapper tbody tr td:last-child button svg path {
    fill: #ff8181;
}
.licensesGreen span {
    background-color: #19b15b;
}
.licensesOrange span {
    background-color: #fcb034;
}
.licensesGreen span,
.licensesOrange span {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: 39px;
    height: 24px;
    border-radius: 27px;
    font-weight: 500;
}
.pendingButtonsWrapper {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-left: 15px;
}
.pendingButtonsWrapper button {
    margin: 0;
    margin-bottom: 35px;
}

.error {
    color: #ff8181;
    margin: 0;
    margin-top: 20px;
}

.wrapper .select {
    width: 100%;
    background: #676767;
    color: white;
    border: none;
}
