.container {
    width: 500px;
}
.horizontalLine {
    border: 0;
    height: 0.6px;
    background-color: white;
}
.horizontalLineCell {
    border: 0;
    height: 0.6px;
    background-color: white;
    width: 100%;
}
.table {
    display: table;
    width: 100%;
    border-collapse: collapse;
}
.row {
    display: table-row;
    width: 100%;
}
.cell {
    display: table-cell;
    /* border: 1px solid white; */
    padding: 5px;
}
.comment {
   margin-left: 2rem;
}
.input[type="comment"] {
    display: none;
}