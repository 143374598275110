.wrapper {
    display: inline-block;
    padding: 5px;
    border-radius: 5px;
    margin: 2px;
    font-size: 0.8rem;
}
.light-grey {
    color: black;
    background: rgb(207, 207, 207);
    /* border: 0.1px solid black; */
}
