.toggle {
    display: flex;
    align-items: center;
}

.toggle p {
    margin-right: 10px;
}

.selectItem {
    margin-right: 10px;
}

.status h4 {
    margin-top: 10px;
}

input:disabled {
    color: #000;
    background-color: #fff;
}
