.wrapper {
    padding: 60px;
}
.header {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    gap: 50px;
}
.buttons {
    display: flex;
    flex-direction: row;
    gap: 20px;
}
.types {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 100vh;
    gap: 50px;
}
.type h2 {
    margin-bottom: 20px;
}
.type ul {
    list-style: none;
    padding: 0;
    margin: 0;
    justify-content: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 20px;
}
