.info {
    max-width: 300px;
    text-align: center;
    flex-grow: 1;
}
.info h3,
.info p {
    margin: 15px 0;
}
.form {
    max-width: 250px;
    flex-grow: 4;
}
.form input,
.form .button {
    width: 100%;
}
