.wrapper {
    position: relative;
    margin: var(--top-margin) auto calc(var(--bottom-margin) + 100px) auto;
    width: 80%;
    color: var(--main-white);
}
.message {
    font-size: 1rem;
    font-weight: 100;
    color: rgb(218, 171, 44);
}
.savedFilter {
    top: 80px;
    position: relative;
}
.savedFilter table {
    width: 100%;
    border-collapse: collapse;
}
.savedFilter thead {
    text-align: left;
    border-bottom: 1px solid var(--bright-grey);
}
.savedFilter tr:nth-child(even) {
    background: var(--bright-grey);
}
.savedFilter td,
.savedFilter th {
    padding: 25px 15px;
}
.savedFilter td:last-child {
    padding-right: 0;
}
.savedFilter th {
    min-width: 110px;
    font-weight: normal;
    font-size: 1rem;
    cursor: pointer;
}
.loading {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    color: #c5c5c5;
}
.link {
    cursor: pointer;
}

.deleteBtn:hover {
    background-color: rgb(145, 0, 0);
    border-color: rgb(145, 0, 0);
}
