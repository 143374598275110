.wrapper {
    padding: 12.5px 10px;
    background: rgb(56, 56, 56);
    border-radius: 5px;
    margin: 15px 0;
    border: 0.5px solid white;
}

.link {
    cursor: pointer;
    margin-bottom: 5px;
}
