.settings ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-flow: row wrap;
    gap: 15px;
    margin: 10px 0;
}
.settings li {
    padding: 5px;
    background: #232323;
    border-radius: 5px;
    color: rgb(181, 182, 189);
}
