.tableContainer {
    height: 100%;
}
.results {
    position: relative;
    height: 80vh;
    min-width: 500px;
}
.results p {
    font-size: 0.8rem;
    font-weight: 100;
    color: #aaa;
    margin: 10px 0;
}
.tableRow {
    display: grid;
    grid-template-columns: 3fr 2fr;
    padding: 5px 10px 10px 10px;
}
.tableRow span {
    display: flex;
    align-items: center;
}
.tableRow span:not(:first-child) {
    padding-right: 20px;
}
.header {
    background: #252525;
    border: 1px solid #353535;
    border-radius: 5px;
    margin-bottom: 10px;
}
.marked {
    color: rgb(240, 213, 145);
}
.hovered {
    box-shadow: 0px 0px 2px 2px rgb(158, 158, 158);
    font-size: 1.3rem;
    padding: 20px 10px;
}
.link {
    cursor: pointer;
}
.indicator {
    display: flex;
    justify-content: right;
    position: absolute;
    top: 50px;
    z-index: 100;
}
.indicatorContent {
    color: white;
    font-size: 1.2rem;
    padding: 10px;
    background: #232323;
    transform: translateX(-25px);
}
.indicatorArrow {
    width: 0;
    height: 0;
    border-left: 22px solid transparent;
    border-right: 22px solid transparent;
    border-bottom: 22px solid #232323;
    display: block;
    position: absolute;
    bottom: 0;
    top: -21.5px;
    left: 20px;
}

.restrictingTitle {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
}
.restrictingTitle h4 {
    padding-top: 20px;
}
