.title {
    cursor: pointer;
    display: flex;
    flex-flow: row;
    align-items: center;
    margin-bottom: 10px;
}
.title svg {
    width: 30px;
    height: 30px;
    padding: 0;
}
.title h4 {
    margin: 0;
}
