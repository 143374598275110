/* Login page */
.wrapper {
    position: relative;
    background: linear-gradient(#2a2a2a, #37537e);
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-columns: auto 30%;
}
.splash {
    position: relative;
    grid-column: 1 /2;
    display: flex;
    flex-flow: column nowrap;
    justify-content: end;
}
.heart {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
}
.heart img {
    width: 800px;
}
.title {
    margin: 0 50px;
    bottom: 0;
}
.title button {
    font-weight: 600;
    padding: 15px 20px;
    border-radius: 10px;
    border: none;
}
.contact {
    margin: 30px auto;
    width: 100%;
    display: flex;
    justify-content: center;
}
.contact a {
    text-align: center;
    margin: 20px;
    max-width: 100px;
}
.contact a img {
    display: block;
    filter: invert(100);
    margin: 10px auto;
    width: 50%;
}
.auth-box {
    grid-column: 2/3;
    right: 0;
    top: 0;
    display: flex;
    flex-flow: column nowrap;
    background: #2a2a2a;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}
.logo {
    margin-top: 40px;
    flex-grow: 1;
    width: 80%;
}
.logo img {
    width: 100%;
}

@media (max-width: 768px) {
    .wrapper {
        grid-template-columns: 1fr;
    }
    .splash {
        display: none;
    }
    .auth-box {
        grid-column: 1 / -1;
        width: 100%;
    }
}
