.wrapper {
    display: inline-block;
}

.search {
    width: 90%;
}
.list {
    border-radius: 10px;
    padding: 20px;
    margin: 10px 0;
    max-width: 400px;
    height: 35vh;
    border: 1px solid #ffffff;
    overflow-y: auto;
}

.dropdown {
    height: 30vh;
    width: 90%;
}

option {
    margin-left: 20px;
}
