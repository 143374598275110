.licenses {
    position: relative;
}
.licenses table {
    border-collapse: collapse;
    width: 90%;
    margin-top: 30px;
    margin-left: 2%;
}
.licenses thead {
    text-align: center;
}

.licenses th {
    padding: 10px 0;
}
.licenses th:first-child,
.licenses td:first-child {
    padding: 0 20px;
}
.licenses tr:nth-child(2n) {
    background: var(--bright-grey);
}
.licenses td {
    color: #999999;
    width: 33%;
    text-align: center;
}
.licenses td:nth-child(3) {
    text-align: center;
}
.licenses .edit {
    color: #999999;
}
