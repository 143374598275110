.textArea,
.edit-TextArea {
    margin: 0;
    /* margin-left: 15px; */
    padding: 0;
    padding-top: 5px;
    width: 100%;
    resize: none;
    overflow-y: hidden;
    background-color: transparent;
    border: none;
    font-family: inherit;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    color: white;
    height: 30px;
    padding: 5px;
}

/* .edit-TextArea {
    border: 1px solid rgb(128, 128, 128);
    margin: 15px 5px;
} */
