.wrapper {
    height: 100%;
}
.content {
    overflow: auto;
    height: 100%;
    padding: 0px 50px 100px 50px;
}
.bar {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
    margin: 0 25px 25px 25px;
    border-radius: 15px;
    background: rgb(62, 62, 62);
}
.button {
    margin: 0;
}
