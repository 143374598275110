.wrapper {
    font-size: 0.8rem;
    color: white;
    display: flex;
    flex-flow: column;
    gap: 15px;
    border-radius: 10px;
    background: black;
    padding: 20px 15px;
}
