.table-wrapper {
    overflow: hidden;
    border-radius: 10px;
    width: 100%;
}

.table {
    border-collapse: collapse;
    margin: 60px 0;
    width: 100%;
}

thead tr th {
    font-size: 19px;
    line-height: 135%;
    letter-spacing: 0.18px;
    text-align: left;
    font-weight: 300;
    border: none;
    padding: 17px 22px;
    background-color: var(--main-grey-with-opacity);
}

tr {
    background: var(--bright-grey);
}

tr:hover {
    background-color: var(--extra-bright-grey);
}

thead tr:hover {
    cursor: default;
    background-color: var(--bright-grey);
}

.table tr:last-child td {
    border-bottom: none;
}

.table tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
}

.table tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
}

.table tr td:first-child {
    border-left: none;
}

.table tr td:last-child {
    border-right: none;
}

.table thead tr th:first-child {
    border-radius: 10px 0 0 0;
}

.table thead tr th:last-child {
    border-radius: 0 10px 0 0;
}

.table td {
    word-break: break-word;
    padding: 15px 22px;
    border: 1px solid var(--main-grey-with-opacity);
}

.expired {
    color: #f00;
}

.soonToExpire {
    color: #fcb034;
}

.verification,
.expiration {
    text-align: center;
    width: 10%;
}

.loading-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
}

.empty-state {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
}
