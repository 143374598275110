.wrapper {
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(51, 51, 51);
    background: rgb(26, 26, 26);
    border-radius: 8px;
}
.approve {
    background: rgba(98, 145, 73, 0.2);
    border: 1px solid rgba(139, 211, 106, 1);
}
.date {
    font-size: 0.8rem;
}
.log {
    padding: 16px;
    display: flex;
    flex-flow: column;
    gap: 12px;
}
.title {
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 0;
    border-bottom: 1px solid rgb(51, 51, 51);
    padding: 12px 16px;
    border-radius: 8px 8px 0 0;
}
.approve .title {
    background: rgba(139, 211, 106, 0.5);
    border-bottom: 1px solid rgba(139, 211, 106, 1);
}
.entry {
    font-size: 0.9rem;
    display: flex;
    flex-flow: column;
    gap: 6px;
    overflow-wrap: anywhere;
}
