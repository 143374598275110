.wrapper {
    height: 100%;
}
.wrapper img {
    max-height: 50px;
    height: 100%;
}
.noStatus {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 45px;
    width: 45px;
    border: 5px solid #f8f8f8;
    color: #f8f8f8;
    border-radius: 100px;
    font-weight: bold;
    font-size: 1.8rem;
    background: #173aba;
}
.small {
    height: 20px;
    width: 20px;
    border: 1px solid #f8f8f8;
    font-size: 0.8rem;
}
.mr {
    font-size: 1rem;
}
.unknown {
    font-size: 0.5rem;
}
