.wrapper {
    text-align: left;
}

.content {
    min-height: 150px;
    border: 1px solid gray;
    padding: 10px;
    border-radius: 4px;
    background: #444;
    color: #fff;
    margin-bottom: 30px;
}

.content:focus {
    outline: none;
}

.content ul {
    list-style: initial;
    margin-left: 20px;
}

.menubar {
    background: #444;
    padding: 5px;
    margin-bottom: 4px;
    border-radius: 4px;
    border: 1px solid gray;
    margin-top: 6px;
}

.menuButton {
    background: none;
    border: none;
    cursor: pointer;
    margin-right: 10px;
    color: #fff;
    padding: 4px;
    border-radius: 4px;
}

.isActive {
    color: blue;
    background: var(--main-white);
    color: #444;
}

.menuButton:hover {
    color: #555;
}

.infoLabel {
    font-size: 0.85rem;
    margin-top: 20px;
}
