.form input:disabled {
    color: black;
    background: #888;
}
.form {
    /* padding-top: 20px; */
    margin: auto;
    width: 80%;
    display: flex;
    flex-flow: column;
}
.form textarea {
    width: 100%;
}
.form label,
.form small {
    text-align: left;
}
