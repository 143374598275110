.wrapper {
    margin: 50px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.wrapper h1 {
    margin-bottom: 10px;
}
.implant {
    display: flex;
    gap: 50px;
}
.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}
.filters {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
