input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
.wrapper * {
    display: block;
}
.input-wrapper {
    position: relative;
    width: 100%;
    margin: 4px 0;
}
.fixed {
    height: 48px;
}
.input-wrapper textarea {
    width: 300px;
    height: 100px;
}
.input,
.input-error {
    width: 100%;
    height: 100%;
    padding: 0 16px;
    padding-right: 40px;
    font-size: 1rem;
    line-height: 1.5rem;
    border-radius: 5px;
}
.input {
    border: 3px solid transparent;
}
.input-error {
    border: 3px solid red;
}
.info {
    font-size: 0.8125rem;
    color: rgba(255, 255, 255, 0.8);
    padding: 0px 10px 10px 10px;
}
.wrapper label {
    display: inline;
    font-size: 0.875rem;
    font-weight: 500;
    letter-spacing: 0.4px;
}
.supporting-text {
    max-width: 400px;
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0.4px;
}
.wrapper button {
    position: absolute;
    top: 25%;
    right: 20px;
    background-color: transparent;
    border: none;
    z-index: 1;
}
.warning-icon {
    position: absolute;
    top: 25%;
    right: 20px;
    pointer-events: none;
}
.hidden {
    display: none;
}
.warning-icon svg {
    color: rgb(255, 0, 0);
}
.input:hover,
.input:focus,
.input-error:hover,
.input-error:focus {
    border: 3px solid var(--main-blue);
}
.input-wrapper button:hover + .input {
    border: 3px solid var(--main-blue);
}
.error {
    color: rgb(255, 100, 100);
}
.focused {
    color: var(--main-blue);
}
.disabled {
    pointer-events: none;
}
.disabled::placeholder {
    color: lightgrey;
}
