.info {
    max-width: 400px;
    text-align: center;
    flex-grow: 1;
}
.info h3,
.info p {
    margin: 0 0 30px 0;
}
.button {
    margin: 50px 0 10px 0;
}
.form {
    flex-grow: 4;
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 60%;
}
.form div {
    width: 100%;
}
.form input,
.form select,
.button {
    width: 100%;
}
.form label a {
    text-decoration: underline;
}
.form-alert {
    background-color: rgba(244, 180, 180, 0.7);
    border-radius: 2px;
    height: 40px;
    line-height: 40px;
    width: 223px;
    margin: auto;
    color: rgba(59, 20, 20, 0.7);
}
.form-alert p {
    font-size: 0.8125rem;
}

.error {
    color: rgb(255, 100, 100);
    font-size: 0.75rem;
}
