.wrapper {
    margin: 50px auto;
    width: 80%;
}
.titleWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.roomItem {
    margin-bottom: 4em;
}
.rowWrapper {
    display: flex;
    align-items: center;
    gap: 15px;
    border-bottom: 1px solid var(--main-white);
    padding-bottom: 5px;
    padding-top: 10px;
    font-weight: 200;
}
.rowWrapper > div {
    width: 50%;
}
.roomItem p {
    font-weight: 600;
}
.roomItem h3 {
    margin: 1em 0;
    font-weight: 600;
}
.roomItem h3 button {
    margin: 0;
    padding: 0;
    margin-left: 25px;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
}
.addButton {
    margin: 0;
}
