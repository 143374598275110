.wrapper {
    /* border: 1px solid #ccc; */
}

.wrapper table {
    /* margin: 1em; */
    width: 100%;
    border-collapse: collapse;
    /* box-sizing: border-box; */
}

.wrapper table th,
.wrapper table td {
    color: #fff;
    padding: 0em 3em;
}

.wrapper thead {
    background: rgba(128, 128, 128, 0.25);
    height: 2.875em;
}

.wrapper thead tr th:first-child {
    border-radius: 5px 0px 0px 5px;
}

.wrapper thead tr th:last-child {
    border-radius: 0px 5px 5px 0px;
}

.wrapper th {
    font-weight: 400;
    font-size: 1rem;
    letter-spacing: 0.031rem;
    color: var(--main-white);
}

.wrapper tbody tr td {
    text-align: center;
    height: 2.875rem;
    border-bottom: 1px solid rgba(128, 128, 128, 0.25);
}

.wrapper thead tr th,
.wrapper tbody tr td {
    text-align: center;
    padding-left: 1rem;
}

.wrapper tbody tr td {
    cursor: default;
    font-size: 1rem;
}

.wrapper tbody tr td button {
    padding: 0.5rem;
    margin: 0.5rem;
}

.overview {
    display: flex;
    width: 100%;
    /* border: 1px solid #ccc; */
    flex-flow: row wrap;
}
.overview > div {
    /* max-height: calc(100vh - 80px); */
    overflow: auto;
    /* border: 1px solid #ccc; */
    /* max-width: 80%; */
}
.table {
    /* display: grid;
    /* grid-template-columns: 3fr 2fr 1fr 1fr 1fr; */
    grid-template-columns: auto auto auto auto min-content min-content min-content min-content;
}
.first,
.second,
.third,
.fourth,
.fifth,
.sixth,
.seventh,
.eighth {
    display: flex;
    align-items: center;
    border-top: 1px solid #ccc;
}
.first {
    grid-column: 1 / 2;
}
.second {
    grid-column: 2 / 3;
}
.third {
    grid-column: 3/ 4;
}
.fourth {
    font-style: italic;
    grid-column: 4 / 5;
}
.fifth {
    grid-column: 5 / 6;
}
.sixth {
    grid-column: 6 / 7;
}
.seventh {
    grid-column: 7 / 8;
}
.eighth {
    grid-column: 8 / 9;
}
.link {
    display: flex;
    height: 20px;
}

.resultsRow {
    display: none;
    transition: display 1s ease, max-height 1s ease, opacity 1s ease;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
}

.resultsRow.open {
    display: table-row;
    height: auto;
    opacity: 1;
}
