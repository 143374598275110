/* update this with more general design */

.item {
    margin: 0;
    list-style: none;
}
.item a {
    margin: 0 15px 0 67px;
    padding: 4px 0;
    padding-left: var(--nav-item-padding);
}
.item a:hover {
    background-color: var(--transparent-bright-grey);
}
.item .is-active {
    background-color: var(--extra-bright-grey);
}
.item .is-active:hover {
    background-color: var(--extra-bright-grey);
}
