/* Results page */
.wrapper {
    position: relative;
    margin: var(--top-margin) auto calc(var(--bottom-margin) + 100px) auto;
    width: 95%;
    color: var(--main-white);
}
/* .wrapper:after {
    display: block;
    width: 100%;
    content: '';
    height: 100px;
    background: white;
} */
.search {
    /* position: absolute; */
    margin-bottom: 50px;
}
.loading {
    width: 100%;
    height: 500px;
    color: #c5c5c5;
    flex-flow: column;
}
.loading,
.noresult {
    gap: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loupe {
    height: 60px;
}
.loupe svg {
    height: 100%;
}
.reportbtn {
    float: right;
}
.missing {
    filter: invert(100);
    height: 30px;
    margin: -5px 6px -10px -10px;
}
.section {
    display: flex;
    gap: 40px;
}
.filters {
    position: -webkit-sticky;
    position: sticky;
    top: 40px;
}
.results {
    /* top: 120px; */
    position: relative;
    /* flex-grow: 3; */
}
.results h2 {
    margin-bottom: 20px;
}
.list {
    width: 100%;
    border-collapse: collapse;
}
.list thead {
    text-align: left;
    border-bottom: 1px solid var(--bright-grey);
}
.list tr:nth-child(2n) {
    background: var(--bright-grey);
}
.list td,
.list th {
    padding: 15px;
}
.list td:last-child {
    padding-right: 0;
}
.list th {
    min-width: 150px;
    font-weight: normal;
    font-size: 14pt;
    cursor: pointer;
}
.list tr {
    cursor: pointer;
}

.edit-button {
    position: absolute;
    transform: translateX(-80px);
}

.suggestedText {
    width: 200%;
    font-weight: 500;
    position: absolute;
    margin-top: 10px;
}
.suggestedText span {
    color: rgb(44, 131, 218);
}
/* .approved {
    text-align: center;
} */
.approved svg {
    color: rgb(112, 197, 73);
    height: 40px;
    width: 40px;
}
