.messageWrapper {
    display: flex;
}
/* .messageWrapper {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
} */
.successStyle,
.errorStyle {
    padding: 8px 15px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-weight: 400;
    border-radius: 5px;
}
.successStyle {
    background: #398d33ad;
}
.errorStyle {
    background: rgba(194, 46, 46, 0.713);
}
