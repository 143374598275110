.wrapper ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
    list-style: none;
    padding: 0;
    margin: 0;
}
.wrapper li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}
