.required::after {
    content: ' *';
    color: red;
}

.brand {
    display: flex;
    flex-direction: column;
}

.brand label {
    margin-top: 10px;
}

.brand input,
.brand select {
    background: var(--main-selectbox);
    color: var(--main-white);
    max-height: 48px;
}

.infoLabel {
    font-size: 0.85rem;
}

.typeCheckboxWrapper {
    margin-bottom: 15px;
}

.inputWrapper {
    display: flex;
    width: 60%;
    margin-bottom: 25px;
}

.flex {
    display: grid;
    gap: 50px;
    grid-template-columns: 1fr 1fr;
}

.greyBtn {
    border: 2px solid var(--main-white);
    background: #4e4e4e;
    margin-top: 30px;
    width: 150px;
    color: var(--main-white);
    font-weight: 600;
}
