.item {
    opacity: 0.7;
    background: #333;
    padding: 5px;
    font-size: 0.8rem;
    border-radius: 5px;
}
.category {
    color: #ccc;
    flex-flow: row wrap;
    display: flex;
    gap: 10px;
}
.wrapper {
    flex-direction: column;
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}
