.wrapper {
    margin: 60px auto;
    width: 1015px;
    color: #ffffff;
}

.wrapper h1 {
    color: #ffffff;
    font-weight: 400;
    font-size: 2.125rem;
    border-bottom: 1px solid rgba(128, 128, 128, 0.25);
    padding-bottom: 20px;
}