.examples {
    position: absolute;
    max-height: 400px;
    overflow: auto;
    background: #444;
    border-radius: 10px;
    z-index: 10;
}
.examples li {
    padding: 7px 15px;
}

.examples li:hover {
    background: var(--main-selectbox);
    cursor: pointer;
}
.wrapper input {
    width: 100%;
    background: var(--main-selectbox);
    color: var(--main-white);
}

.wrapper label {
    font-size: 0.85rem;
}
