.wrapper {
    height: 100%;
    width: 60%;
    margin: 50px auto;
    position: relative;
}
.success {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 40px;
}
.buttons {
    display: flex;
    gap: 20px;
}
