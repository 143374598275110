.backgroundContainer {
    padding: 30px;
    border-radius: 15px;
    border: 1px solid #2e2e2e;
    background: #181818;
}

.info,
.backgroundContainer,
.conditionals {
    display: flex;
    flex-flow: column;
    width: 100%;
}

.backgroundContainer input,
.backgroundContainer select,
.column input,
.column option {
    background: var(--main-selectbox);
    color: var(--main-white);
    max-height: 48px;
    /* margin-bottom: 25px; */
}

.backgroundContainer textarea {
    background: var(--main-selectbox);
    color: var(--main-white);
    line-height: 150%;
    /* margin-bottom: 25px; */
}

/* .column label, */
.column input:not([type='checkbox']),
.column select,
.column textarea {
    width: 100%;
}

.infoLabel {
    font-size: 0.85rem;
}

.conditional-inputs {
    display: flex;
}
.conditionalGroup {
    flex-grow: 1;
}

.column {
    margin-top: 30px;
}

.inputWrapper {
    display: flex;
    justify-content: flex-start;
}

@media (min-width: 1900px) {
    .column {
        margin-top: 0px;
    }
    .flex {
        display: grid;
        gap: 30px;
        grid-template-columns: 1fr 1fr;
    }
}
