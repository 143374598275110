.tooltip {
    position: relative;
}
.tooltip span {
    display: none;
    width: 400px;
    position: absolute;
    background: rgba(200, 200, 200, 0.9);
    color: black;
    padding: 15px;
    border-radius: 5px;
    margin: 10px 5px;
}
.tooltip:hover span {
    display: inline-block;
}
