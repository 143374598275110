.wrapper {
    width: 80%;
    display: flex;
    gap: 40px;
}
.wrapper ul {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: auto;
    height: 75vh;
}
.wrapper li {
    cursor: pointer;
    list-style: none;
    margin: 0;
    padding: 10px;
}
.category {
    width: 40%;
    border-right: 1px solid #494949;
    padding-right: 20px;
}
.selected {
    border-radius: 10px;
    background: #494949;
}
