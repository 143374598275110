.container {
    width: 100%;
    display: block;
    border: 1px solid red;
}

.implant {
    display: flex;
    flex-direction: column;
    gap: 50px;
    margin-bottom: 50px;
}

.backgroundContainer {
    padding: 30px;
    border-radius: 15px;
    border: 1px solid #2e2e2e;
    background: #181818;
}

.info,
.backgroundContainer,
.conditionals {
    display: flex;
    flex-flow: column;
    width: 100%;
}
.backgroundContainer label,
.column label {
    margin-top: 10px;
}

.backgroundContainer input,
.backgroundContainer select,
.column input,
.column option {
    background: var(--main-selectbox);
    color: var(--main-white);
    max-height: 48px;
    margin-bottom: 25px;
}

.backgroundContainer textarea {
    background: var(--main-selectbox);
    color: var(--main-white);
    line-height: 150%;
    margin-bottom: 25px;
}

.inline-inputs {
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;
    align-items: center;
}

.conditional-inputs {
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;
    align-items: flex-start;
}

.conditionalGroup input,
.conditionalGroup select {
    margin-bottom: 0;
}

.conditionalGroup {
    margin-bottom: 30px;
}

.conditional-inputs > div,
.inline-inputs > div {
    flex-grow: 1;
    position: relative;
}

.inline-inputs select,
.inline-inputs input,
.inline-inputs button {
    width: 100%;
}

.customFields svg,
.column svg {
    padding-right: 0;
    margin: 0;
}

.infoLabel {
    font-size: 0.85rem;
}

.column td textarea {
    width: 100%;
    height: 0;
    resize: none;
}

.typeCheckboxWrapper {
    margin-bottom: 15px;
}

.loading-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
}

.inputWrapper {
    display: flex;
    width: 60%;
    margin-bottom: 25px;
}

.flex {
    display: grid;
    gap: 50px;
    grid-template-columns: 1fr 1fr;
}

.additionalInfo {
    flex: 0 0 45%;
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.column {
    flex: 1;
}

.column label,
.column input:not([type='checkbox']),
.column select,
.column textarea {
    width: 100%;
}

.greyBtn {
    border: 2px solid var(--main-white);
    background: #4e4e4e;
    margin-top: 30px;
    width: 150px;
    color: var(--main-white);
    font-weight: 600;
}

.save {
    display: flex;
    margin-top: 30px;
    justify-content: flex-start;
    gap: 15px;
}

.customFields {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
}

.customFieldContainer {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 8px;
    align-items: start;
}

.fieldInputContainer {
    display: grid;
    grid-template-rows: auto auto;
}

.fieldInputContainer input {
    margin-bottom: 0;
}

.buttonContainer {
    display: flex;
    justify-content: flex-end;
    align-items: start;
}

.customBtn {
    width: 160px;
}

.inline-inputs .selectContainer {
    flex-grow: 1;
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 100%;
}

.inline-inputs .warningIcon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-45%);
    color: orange;
}

.preview .customFields,
.preview .flex {
    grid-template-columns: 1fr;
}

.fetchBtn {
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fetchBtn button {
    height: 48px;
}

.autofilled {
    background: 'rgb(128, 128, 128)';
    border: '1px solid rgb(78, 165, 217)';
    color: 'white';
}
