.model {
    display: flex;
    flex-direction: column;
}

.infoLabel {
    font-size: 0.85rem;
}

.required::after {
    content: ' *';
    color: red;
}

.model label {
    margin-top: 10px;
}

.model input {
    background: var(--main-selectbox);
    color: var(--main-white);
    max-height: 48px;
}
