.appearance .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.appearance .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.appearance .switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    /*   -webkit-transition: 0.4s; */
    transition: 0.4s;
}

.appearance .switch .slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    /*  -webkit-transition: 0.4s; */
    transition: 0.4s;
}

.appearance .switch input:checked + .slider {
    background-color: rgb(106, 111, 240);
}

.appearance .switch input:focus + .slider {
    box-shadow: 0 0 1px rgb(106, 111, 240);
}

.appearance input:checked + .slider:before {
    /* -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px); */
    transform: translateX(26px);
}

/* Rounded sliders */
.appearance .slider {
    border-radius: 34px;
}

.appearance .slider:before {
    border-radius: 50%;
}

/* Circle Icon */
.appearance .icon {
    width: 44px;
    height: 44px;
    background-color: var(--main-purple);

    line-height: 44px;
    text-align: center;
    border-radius: 22px;
}
.appearance {
    display: flex;
    align-items: center;
    gap: 50px;
    margin-top: 60px;
}
.appearance .iconPart {
    display: flex;
    align-items: center;
    gap: 15px;
    /*   color: var(--main-white); */
}
.appearance .iconPart span {
    color: var(--main-white);
}
