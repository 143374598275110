.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    /* padding: 50px 60px; */
}
.buttons {
    display: flex;
    justify-content: space-around;
    /* gap: 20px; */
}
