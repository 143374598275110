.wrapper {
    position: relative;
    margin: var(--top-margin) auto calc(var(--bottom-margin) + 100px) auto;
    width: 80%;
    color: var(--main-white);
}

.userPage {
    top: 80px;
    position: relative;
    width: 75%;
}

.userEditSection {
    margin-top: 64px;
}

.userInfo {
    display: flex;
    flex-direction: column;
}

.userPassword {
    margin-top: 48px;
}

.userDetails {
    display: flex;
    justify-content: space-between;
}

.submitBtnField {
    display: flex;
    flex-direction: column;
    align-self: end;
}

.btnError {
    color: rgb(255, 100, 100);
    font-size: 0.8rem;
    max-width: 400px;
}

.submitBtn {
    width: fit-content;
    padding: 7px 25px;
    font-weight: 400;
    margin-bottom: 10px;
}

.loading {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    color: #c5c5c5;
}
