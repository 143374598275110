.wrapper {
    position: relative;
    margin: 50px auto;
    width: 80%;
}
.videosWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-columns: minmax(1fr, 315px);
    column-gap: 26px;
    row-gap: 44px;
}
.videoFlexRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.videoFlexRow h1,
.videoFlexRow p {
    font-weight: 400;
    color: #ffffff;
}
.videoFlexRow h1 {
    font-size: 2.125rem;
    margin: 0 0 20px;
}
.videoFlexRow p {
    font-size: 1.5rem;
    letter-spacing: 0.18px;
    margin: 0 0 30px;
}
.loading {
    display: flex;
    justify-content: center;
    align-items: center;
}
