.loadingwrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
}

.commentWrapper {
    margin-top: 2rem;
}
