.comment-form,
.edit-form {
    display: flex;
    height: fit-content;
    border-radius: 15px;
    color: rgb(128, 128, 128);
    font-family: inherit;
}
.comment-form {
    padding: 13px 20px;
    flex-flow: row nowrap;
    align-items: center;
    background: rgb(96, 145, 73, 0.2);
}
.edit-form {
    flex-flow: row nowrap;
    align-items: center;
    gap: 5px;
}
.button-container {
    display: flex;
    justify-content: space-between;
    align-items: last baseline;
}
.button {
    margin: 0;
    padding: 0;
}
.edit-form .button-container {
    margin-bottom: 15px;
}
.edit-form .button {
    margin-bottom: 15px;
    margin: 0px 5px;
}
