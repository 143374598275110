.wrapper {
    margin-bottom: 20px;
}
.toggle {
    border-top: 0.1px solid white;
    display: flex;
    justify-content: center;
    padding: 15px;
}
.toggleText {
    display: flex;
    align-items: center;
}
.toggle:hover {
    cursor: pointer;
}
.details {
    display: flex;
    flex-flow: column;
    gap: 10px;
}
