.wrapper {
    top: 0;
    left: 0;
    z-index: 1000;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(100, 100, 100, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}
.popup-box {
    position: relative;
    /* text-align: center; */
    border-radius: 10px;
    background: var(--main-grey);
    box-shadow: #2a2a2a 2px 2px 20px 2px;
    width: 500px;
    padding: 30px;
}
.popup-box h2,
.popup-box p {
    text-align: center;
}
.modal-close {
    opacity: 0.5;
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 15px;
    width: 20px;
    height: auto;
}

.modal-close svg {
    filter: invert(100);
    width: 100%;
    height: 100%;
}

.scrollable-box {
    padding: 0px;
    text-align: left;
    height: 90%;
    width: auto;
    max-width: 60%;
}
.scrollable-content {
    overflow-y: scroll;
    height: 100%;
    padding: 40px 50px;
}
.content {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--form-item-gap);
}
