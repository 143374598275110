.container {
    height: 100%;
    width: 100%;
    overflow: hidden;
    border-radius: 20px;
    position: relative;
}
.image {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    border-radius: 20px;
    cursor: pointer;
}
