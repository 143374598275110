.wrapper {
    width: var(--nav-width);
    font-size: var(--nav-text-size);
    background: var(--nav-background);
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    transition: transform 0.3s ease-in-out;
    position: relative;
}

.wrapper a {
    color: var(--main-white);
}

.logo {
    height: 153px;
    display: flex;
    justify-content: center;
    padding-top: var(--top-margin);
}

.logo img {
    width: 200px;
}

.app-menu,
.extra-menu {
    display: flex;
    flex-direction: column;
    flex-grow: 5;
    list-style-type: none;
    padding: 0;
    gap: var(--nav-item-gap);
    position: relative;
}

.menu-item-container {
    display: flex;
    align-items: center;
}

.sub-menu ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: var(--nav-width);
}

.sub-menu ul li {
    width: var(--nav-width);
}

.userMenu {
    height: 195px;
    width: fit-content;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: var(--bottom-margin);
    justify-content: center;
}

.userLink {
    padding: 12px 0;
    cursor: pointer;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--nav-item-gap);
    font-size: var(--nav-text-size);
}

.userLink svg,
.logout svg {
    width: var(--nav-icon-size);
    height: var(--nav-icon-size);
}

.logout {
    padding: 12px 0;
    font-size: var(--nav-text-size);
    color: var(--main-white);
    cursor: pointer;
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--nav-item-gap);
}

.hamburger {
    display: none;
    cursor: pointer;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 1000;
}

.hamburger div {
    width: 25px;
    height: 3px;
    background-color: var(--main-white);
    margin: 5px 0;
    transition: 0.4s;
}

@media screen and (max-width: 768px) {
    .wrapper {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 250px;
        transform: translateX(-100%);
    }
    .logo {
        padding-top: 80px;
    }
    .open {
        z-index: 1;
        transform: translateX(0);
    }

    .hamburger {
        z-index: 2;
        display: block;
    }
}
