.table {
    border-radius: 5px;
    border: 1px solid white;
    color: white;
    font-size: 0.6rem;
}
.key,
.value {
    background: rgb(49, 49, 49);
    padding: 5px 10px;
}
.key {
    border-radius: 5px 5px 0 0;
    background: rgb(109, 109, 109);
    border-bottom: 1px solid white;
}
.value {
    border-radius: 0 0 5px 5px;
}
