.videocard {
    border-radius: 15px;
    background: rgba(128, 128, 128, 0.25);
    color: #ffffff;
}
.tagsWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}
.videoHeader,
.videoBody {
    padding: 12px;
}
.videoHeader {
    display: flex;
    align-items: center;
}
.videoBody {
    padding-bottom: 20px;
}
.author {
    font-size: 1rem;
    font-weight: 500;
    text-transform: capitalize;
}
.authorIcon svg {
    width: 24px;
    height: 24px;
}
.videoImage {
    height: 180px;
}
.videoImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.videoImage,
.videoTitle {
    cursor: pointer;
}
.videoTitle {
    font-size: 1rem;
    font-weight: 600;
    line-height: 23px;
    margin-top: 0;
    margin-bottom: 20px;
}
.videoTitle:hover {
    text-decoration: underline;
}
.tagsWrapper span {
    border-radius: 4px;
    background: rgba(128, 128, 128, 0.25);
    font-size: 0.875rem;
    font-weight: 500;
    letter-spacing: 0.1px;
    padding: 4px 8px;
}
.postTime {
    margin: 0;
    font-size: 12px;
    font-weight: 300;
    line-height: 23px;
}

@media screen and (min-width: 1900px) {
    .videosWrapper {
        grid-template-columns: repeat(4, 1fr);
    }
}
