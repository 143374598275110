.wrapper {
    position: relative;
    display: inline-flex;
}
.options {
    z-index: 1;
    position: absolute;
    width: 300px;
    display: flex;
    flex-flow: column;
    height: 500px;
    overflow: scroll;
    overflow-x: hidden;
}
.options::-webkit-scrollbar {
    display: none;
    background: gray;
    width: 10px;
}
.options::-webkit-scrollbar-thumb {
    /* border-radius: 20px; */
    -webkit-box-shadow: inset 0 0 5px rgb(0 0 0 / 20%);
    background: #c4c1c1;
}
.trigger {
    display: inline-flex;
    border-radius: 5px;
    padding: 10px 12px;
}
.item {
    width: 100%;
}
.item,
.trigger {
    cursor: pointer;
    background: var(--main-selectbox);
}
.item:nth-child(n + 2) {
    border-top: 1px solid black;
}
.item:first-child {
    border-radius: 5px 5px 0 0;
}
.item:last-child {
    border-radius: 0 0 5px 5px;
}
.arrow {
    display: inline-block;
    position: relative;
    height: 15px;
    width: 25px;
}
.arrow::before,
.arrow::after {
    content: '';
    display: inline-block;
    position: absolute;
    bottom: -1px;
    width: 1.6px;
    height: 10px;
    transition: all 0.5s;
}
.arrow::before {
    right: 9px;
    transform: rotate(45deg);
    background-color: white;
}
.arrow::after {
    right: 3px;
    transform: rotate(-45deg);
    background-color: white;
}
.open::before {
    right: 9px;
    transform: rotate(-45deg);
}
.open::after {
    right: 3px;
    transform: rotate(45deg);
}
