.icon {
    color: #5fff8b;
    border: 1px solid #5fff8b;
    border-radius: 5px;
    width: 48px;
    height: 30px;
    text-align: center;
}

.icon span {
    font-size: 14px;
    line-height: 27px;
}
