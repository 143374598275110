.disclaimer {
    background-color: #f0f58c;
    opacity: 0.8;
    padding: 10px 30px;
    border-radius: 5px;
    color: #4a4e0e;
    font-weight: 500;
    font-size: 1.2rem;
}
.disclaimer a {
    text-decoration: underline;
    color: black;
    cursor: pointer;
}
