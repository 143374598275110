.wrapper {
    display: flex;
    flex-flow: column;
    gap: 30px;
    margin: 50px auto;
    width: 80%;
}
.wrapper h2 {
    margin-bottom: 15px;
}
.reapprove {
    margin: 0;
}
.preview {
    width: 40vw;
    margin: 50px;
}
.preview p {
    text-align: left;
}
.approvalBox {
    display: flex;
    flex-flow: column;
    background: rgb(38, 38, 38);
    position: absolute;
    /* z-index: 2000; */
    right: calc(-35% - 20px);
    border-radius: 10px;
    top: 0;
    width: 35%;
    padding: 30px 30px 10px 30px;
}
.approvalBox textarea {
    width: 100%;
}
.approvalBox h4 {
    margin-bottom: 10px;
}
.approvalBox p {
    text-align: left;
    margin-bottom: 10px;
}
