.comment {
    /* color: rgb(211, 211, 211); */
    background: rgb(96, 145, 73, 0.2);
    padding: 15px 20px 20px 20px;
    border-radius: 10px;
    overflow-wrap: anywhere;
}
.comment p {
    font-size: 1rem;
}
/* header */
.commentHeader {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.commentInfo {
    display: flex;
    align-items: center;
}

.username {
    margin-left: 15px;
    color: rgb(233, 241, 255);
    font-size: 1rem;
    font-weight: 400;
}
.dot {
    font-weight: 500 !important;
    margin-left: 7px;
    text-align: center;
}
.time {
    margin-left: 5px;
    color: rgb(196, 196, 196);
    font-size: 0.8rem;
}
.threeDots {
    display: flex;
    justify-content: center;
    width: 36px;
    position: relative;
    padding: 8px;
}
.threeDots:hover {
    background: rgb(76, 76, 76);
    border-radius: 50%;
    cursor: pointer;
}
/* edit popup */
.editPopUp {
    display: flex;
    flex-direction: column;
    position: absolute;
    background: rgba(128, 128, 128, 1);
    width: 123px;
    height: 80px;
    border-radius: 5px;
    margin-top: 13px;
    z-index: 100;
    left: 25px;
}
.menuBottom,
.menuTop {
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    text-transform: capitalize;
    transition: ease-out 300ms;
}
.menuTop {
    border-bottom: 1px solid black;
}
.menuTop:hover,
.menuBottom:hover {
    background: rgba(67, 67, 67, 1);
}
.menuTop:hover {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.menuBottom:hover {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
