.wrapper {
    position: relative;
}
.wrapper input {
    position: absolute;
    left: -9999px;
}
.wrapper span {
    position: relative;
    cursor: pointer;
    padding: 8px;
    display: flex;
    align-items: center;
}
.label:before {
    margin-right: 8px;
}
.wrapper input[type='radio'] + span {
    border-radius: 25px;
}
.wrapper input + span:before {
    display: inline-block;
    flex-shrink: 0;
    content: '';
    /*   background-color: black; */
    background-color: var(--light-grey);
    transition: 0.25s ease;
}
.wrapper input[type='radio'] + span:before {
    width: 15px;
    height: 15px;
    border: 3px solid var(--main-blue);
    border-radius: 50%;
}
.wrapper input[type='checkbox'] + span:before {
    width: 16px;
    height: 16px;
    border: 2px solid var(--main-blue);
    border-radius: 3px;
}
.wrapper input[type='checkbox']:checked + span:before {
    background: var(--main-blue);
}
.wrapper input[type='checkbox']:checked + span:after {
    content: '';
    display: block;
    position: absolute;
    width: 4px;
    height: 8px;
    border: 2px solid #eee;
    border-top: 0;
    border-left: 0;
    left: 15px;
    transform: rotate(45deg) translate(-1px, -1px);
}
.wrapper input[type='radio']:checked + span:before {
    box-shadow: inset 0 0 0 5px var(--main-blue);
}
.labelled input:checked + span,
.labelled span:hover {
    background: var(--bright-grey);
}
