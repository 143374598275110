.user-status span {
    display: block;
    width: 70px;
    margin: 5px;
    padding: 5px 5px;
    border-radius: 20px;
    color: #2a2a2a;
}
.active-user .user-status span {
    background-color: #61e87f;
}
.inactive-user {
    color: #999;
}
.inactive-user .user-status span {
    background-color: var(--main-hideEye);
}
.extended-info {
    display: none;
    width: 100%;
}
.invite-delete {
    display: flex;
    /* text-align: right; */
    justify-content: flex-end;
    gap: 10px;
}
.invite-delete button {
    margin: 0;
}
