.wrapper {
    position: relative;
    background: white;
    width: 100%;
    height: 50vh;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
}
.noResult {
    text-align: center;
    border-radius: 20px;
}
.list {
    width: 55%;
    color: black;
    overflow-y: auto;
}
.result svg {
    margin-right: 10px;
}
.result p {
    line-height: 1rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.implantLink {
    width: 100%;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    padding: 15px;
    border-bottom: 1px solid #e0e0e0;
}
.icon {
    height: 20px;
}
.approved svg {
    color: rgb(112, 197, 73);
}
.result a {
    color: black;
}
.result:last-child .implantLink {
    border-bottom: none;
}
.hover {
    background: rgb(220, 220, 220);
}
.preview {
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    width: 45%;
    border-left: 1px solid #e0e0e0;
}
.seeResults {
    position: absolute;
    bottom: 0;
    width: 55%;
    display: flex;
    justify-content: center;
}
.seeResults button:hover {
    background: white;
    color: black;
}
