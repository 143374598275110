.wrapper {
    width: 60%;
    margin: auto;
}
.wrapper h2 {
    margin: 40px 0;
}
.list {
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.item {
    padding: 25px 40px 25px 20px;
    background: rgb(61, 61, 61);
    /* border-left: 1px solid white; */
    border-radius: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.itemHeader {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.item h3 {
    margin: 0;
}
.details {
    flex-grow: 1;
}
.noresult {
    color: #c5c5c5;
    gap: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loupe {
    height: 60px;
}
.loupe svg {
    height: 100%;
}
