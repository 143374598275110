.implant-list {
    background: var(--extra-bright-grey);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    overflow-x: auto;
    min-height: 55vh;
}
.implant-list > span {
    translate: 0 calc(50% - 50px);
}
.implant-list h2 {
    color: #999;
}
.tabs {
    width: 100%;
    position: relative;
    margin-bottom: 30px;
}
.add {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 0;
}
.status-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-size: 1rem;
    cursor: pointer;
    background: var(--bright-grey);
    border: 1px solid var(--extra-bright-grey);
    height: 48px;
    color: var(--main-white);
    padding: 0 20px;
}
.status-button svg,
.add svg {
    height: 20px;
    translate: 0 -1px;
}

.status-button:nth-child(1) {
    border-radius: 10px 0 0 10px;
}

.status-button:nth-child(4) {
    border-radius: 0 10px 10px 0;
}

.active-button {
    background: #666;
}
