.all,
.small {
    display: inline-flex;
    cursor: pointer;
    text-align: center;
    transition: all 0.3s ease;
}
.all {
    gap: 10px;
    margin: 10px 0px 25px 0px;
    padding: 12px 18px;
    border-radius: 10px;
}
.small {
    font-size: 0.8rem;
    margin: 5px 10px 25px 10px;
    padding: 8px 12px;
    border-radius: 10px;
}
.action {
    color: white;
    background: rgb(20, 42, 54);
    border: 2px solid rgb(20, 42, 54);
}
.dept {
    color: white;
    background: #3c602c;
    border: 2px solid #3c602c;
}
.submit {
    font-weight: 600;
    color: #eee;
    background: var(--main-blue);
    border: 2px solid var(--main-blue);
}
.submit:hover {
    color: var(--main-submitText);
    background: transparent;
}
.primary {
    font-weight: 600;
    color: var(--main-grey);
    background: var(--main-white);
    border: 2px solid var(--main-white);
}
.primary:hover {
    color: var(--main-white);
    background: transparent;
    border: 2px solid var(--main-white);
}
.secondary {
    font-weight: 300;
    color: var(--main-white);
    background: var(--main-secondaryBottomBackground);
    border: 2px solid var(--main-secondaryBottomBackground);
}
.secondary:hover {
    font-weight: 300;
    color: var(--main-white);
    background: transparent;
    border: 2px solid var(--main-white);
}
.tietary {
    font-weight: 500;
    color: var(--main-white);
    text-decoration: underline;
    background: transparent;
    border: none;
}
.cancel {
    font-weight: 500;
    color: var(--main-red);
    text-decoration: underline;
    background: transparent;
    border: none;
}
.submit:active,
.loading {
    background: transparent;
    /* border: 2px solid var(--main-white); */
}
.disabled {
    color: var(--main-grey);
    background: rgb(72, 72, 72);
    border-color: rgb(72, 72, 72);
}
.disabled:hover {
    cursor: not-allowed;
    background: rgb(72, 72, 72);
    color: var(--main-grey);
    border-color: rgb(72, 72, 72);
}
.inlineButton {
    margin: 0;
    /* height: 48px; */
    margin-top: 24px;
}
