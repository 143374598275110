.picker {
    padding: 10px;
    border-radius: 5px;
    border: none;
    margin: 15px;
}
.picker:focus {
    outline: none;
}
.error {
    color: rgb(232, 53, 53);
}
