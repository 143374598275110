.wrapper {
    position: relative;
    background: linear-gradient(#2a2a2a, #37537e);
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.signup-box {
    z-index: 5;
    padding: 50px 100px;
    background: #2a2a2a;
    border-radius: 20px;
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 800px;
}

.heart {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.heart img {
    width: 800px;
}
