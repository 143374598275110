.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--bright-grey);
    padding: 40px;
    z-index: 1001;
    height: 500px;
    /* width: 1000px; */
    border-radius: 15px;
    border: 1px solid #2e2e2e;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.closeIconContainer {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.closeIcon:hover {
    color: #808080;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
