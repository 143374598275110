.info {
    max-width: 300px;
    text-align: center;
    flex-grow: 1;
    margin-bottom: 30px;
}
.info h3,
.info p {
    margin: 15px 0;
}
.form {
    width: 300px;
    flex-grow: 4;
}
.email,
.email input,
.button {
    width: 100%;
}
