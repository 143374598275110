.icon {
    float: right;
    padding-right: 10px;
}
.asc,
.desc {
    height: 8px;
    transform: translateY(-4px);
}
.icon svg {
    width: 8px;
}
