.wrapper {
    height: 100%;
    width: 85%;
    margin: 0 auto;
    position: relative;
}
.add-button {
    position: absolute;
    background: none;
    border: none;
    height: 50px;
    right: 0;
    top: 20px;
}
.add-button svg {
    height: 100%;
}

.add-button:hover {
    cursor: pointer;
}
.overview {
    padding: 50px 0;
}
.title {
    margin-top: 7%;
    > h2 {
        margin: 15px 0;
    }
    > h3 {
        margin: 0;
    }
}
