.wrapper {
    display: inline-block;
    margin: 25px 0px 25px 0px;
}
.file {
    cursor: pointer;
    transition: all 0.5s ease;
    padding: 12px 18px;
    border-radius: 10px;
    font-weight: 300;
    /*  color: var(--main-white); */
    color: var(--main-uploadText);
    background: var(--main-uploadBackground);
    border: 2px solid var(--main-uploadborder);
    border-radius: 2px;
}
.small {
    font-size: 0.8rem;
    margin: 5px 10px 25px 10px;
    padding: 8px 12px;
    border-radius: 10px;
}
.big {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 166px;
    height: 49px;
    border-radius: 0.75rem;
    background-color: var(--grey);
    color: var(--white);
    font-size: 1.32rem;
    font-weight: 500;
    line-height: 1.5rem;
    letter-spacing: 0.0094rem;
    border: none;
    transition: background-color 0.2s ease-in;
}
.file:hover,
.file:focus {
    color: var(--main-uploadText);
    background: var(--main-uploadBackground);
    border: 2px solid var(--main-uploadText);
}
.file input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: fixed;
    z-index: -1;
}
