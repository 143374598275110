.inputsWrapper h3 {
    margin-top: 0;
}
.inputsWrapper input {
    width: 100%;
}
.inputsWrapper {
    font-size: 0.85rem;
    text-align: left;
}
.flexItemsRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}
.label {
    display: block;
    margin-bottom: 5px;
}
.save button {
    margin: 15px 0;
}
