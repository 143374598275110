.wrapper {
    position: relative;
    margin: var(--top-margin) auto calc(var(--bottom-margin) + 100px) auto;
    width: 80%;
    color: var(--main-white);
}

.bookmark {
    top: 80px;
    position: relative;
}
.bookmark table {
    width: 100%;
    border-collapse: collapse;
}
.bookmark thead {
    text-align: left;
    border-bottom: 1px solid var(--bright-grey);
}
.bookmark tr:nth-child(even) {
    background: var(--bright-grey);
}
.bookmark td,
.bookmark th {
    padding: 25px 15px;
}
.bookmark td:last-child {
    padding-right: 0;
}
.bookmark th {
    min-width: 150px;
    font-weight: normal;
    font-size: 1.125rem;
    cursor: pointer;
}
.loading {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    color: #c5c5c5;
}
