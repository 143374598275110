.backgroundContainer {
    padding: 30px;
    border-radius: 15px;
    border: 1px solid #2e2e2e;
    background: #181818;
    display: flex;
    flex-flow: column;
    width: 100%;
}

.backgroundContainer label {
    margin-top: 10px;
}

.backgroundContainer input,
.backgroundContainer select {
    background: var(--main-selectbox);
    color: var(--main-white);
    max-height: 48px;
    margin-bottom: 25px;
}

.backgroundContainer textarea {
    background: var(--main-selectbox);
    color: var(--main-white);
    line-height: 150%;
    margin-bottom: 25px;
}

.customFieldContainer {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 8px;
    align-items: start;
}

.customFields {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    grid-template-columns: 1fr;
}

.customFields svg {
    padding-right: 0;
    margin: 0;
}

.fieldInputContainer {
    display: grid;
    grid-template-rows: auto auto;
}

.fieldInputContainer input {
    margin-bottom: 0;
}

.customBtn {
    width: 160px;
}
