.wrapper {
    display: inline-block;
    position: relative;
}
.placeholder {
    color: #51a6d9;
    font-weight: 500;
    text-decoration: underline;
    z-index: 0;
}
.tip {
    top: 0;
    transform: translateY(-100%);
    border-radius: 10px;
    padding: 15px;
    position: absolute;
    width: 25vw;
    background: #808080;
    z-index: 1;
}
.content {
    margin-top: 15px;
    gap: 15px;
    display: flex;
    flex-flow: row wrap;
}
.content h5 {
    margin: 0;
    font-size: 1rem;
    font-weight: 600;
}
.content p {
    margin-top: 5px;
    font-weight: 300;
}
