.videoModal {
    position: absolute;
    top: 0;
    background: var(--light-grey);
    height: 100%;
    width: 100%;
}
.videoFlexRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.videoModal h2 {
    font-weight: 600;
}
.videoClose {
    font-size: 30px;
    padding: 20px;
    padding-right: 0;
    cursor: pointer;
}
