.wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 300px;
}
.box {
    border: 1px solid var(--extra-bright-grey);
    background: var(--extra-bright-grey);
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    border-radius: 10px;
}
.preview {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.preview svg {
    padding-left: 15px;
    width: 25px;
    height: 25px;
}
.info {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 15px;
    padding-right: 20px;
}
.info a:hover {
    cursor: pointer;
}
.title {
    font-size: 0.8rem;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.description {
    font-size: 0.6rem;
    font-weight: 300;
}

.large {
    flex: 0 1 150px;
}
.large .box {
    gap: 20px;
    width: 150px;
    height: 150px;
    flex-grow: 0;
    flex-flow: column;
    justify-content: center;
}
.large .info {
    width: 100%;
    padding: 10px;
}
.large .preview svg {
    padding-top: 20px;
    padding-left: 0;
    width: 50px;
    height: 50px;
}
.extra {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 0.6rem;
    font-weight: 300;
    background: var(--extra-bright-grey);
    padding: 5px;
    border-radius: 15px;
}
