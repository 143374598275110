.modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow-y: auto;
    height: 100%;
}
.selectTitle {
    margin-bottom: 16px;
}
.link {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: space-between;
}
.link a {
    margin-left: 8px;
    color: #8bd36a;
    font-weight: 400;
}
.link span {
    margin-left: 8px;
    cursor: pointer;
}
