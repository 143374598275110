.select {
    width: 100%;
    height: 100%;
    padding: 0 16px;
    padding-right: 40px;
    font-size: 1rem;
    line-height: 1.5rem;
    border-radius: 5px;
}
.select {
    border: 3px solid transparent;
}

.select:hover,
.select:focus {
    border: 3px solid var(--main-blue);
}
