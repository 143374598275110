.wrapper {
    display: flex;
    flex-flow: column nowrap;
}
.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.row {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
    border: 1px solid #ccc;
    margin: 5px;
    padding: 5px;
}
