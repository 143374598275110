.wrapper {
    margin: 50px auto;
    width: 90%;
    max-width: 1500px;
}
.wrapper h2 {
    display: inline-block;
    width: 50%;
    line-height: 40px;
    margin-top: 60px;
    margin-bottom: 10px;
}
.actions {
    margin-bottom: 10px;
    display: flex;
    gap: 40px;
}
.add {
    cursor: pointer;
    width: 50px;
    height: 50px;
    transform: translateY(8px);
}
.add svg {
    height: 100%;
}
.reminder {
    display: flex;
    border: 1px solid #c5c5c5;
}
.userlist {
    position: relative;
}
.userlist table,
.invitelist table {
    border-collapse: collapse;
    width: 100%;
}
.userlist thead,
.invitelist thead {
    text-align: left;
    background: rgba(128, 128, 128, 0.25);
    height: 46px;
}
.userlist th:first-child {
    border-radius: 5px 0px 0px 5px;
}
.userlist th:last-child {
    border-radius: 0px 5px 5px 0px;
}
.userlist th {
    font-weight: 400;
}
.userlist th:first-child,
.userlist td:first-child {
    padding-left: 15px;
}
.userlist .clickable {
    cursor: pointer;
}
.userlist tr,
.invitelist tr {
    border-bottom: 1px solid rgba(128, 128, 128, 0.25);
    padding: 10px;
}
.userlist td,
.invitelist td {
    padding: 10px 10px 10px 0px;
}
.userlist td:first-child {
    width: 50px;
    text-align: center;
    padding: 10px 25px 10px 0;
}
.invitelist th,
.invitelist td {
    padding-left: 15px;
}
.loading {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    color: #c5c5c5;
}
.manageuser {
    position: relative;
    top: 30px;
}
.manageuser .title {
    font-weight: 600;
    margin-bottom: 5px;
}
.manageuser .licenses {
    display: flex;
    gap: 10px;
}
.manageuser .progressbar {
    position: relative;
}
.manageuser .progressbarBefore,
.manageuser .progressbarAfter {
    /*  content: ''; */
    display: block;
    width: 200px;
    height: 10px;
    background-color: rgb(90, 142, 151, 0.2);
    position: absolute;
    top: 5px;
    border-radius: 5px;
}
.manageuser .progressbarAfter {
    background-color: #9cf4a0;
    width: 1px;
}
.access-info {
    display: flex;
    gap: 100px;
}
