h1 {
    margin-bottom: 30px;
    font-size: 2.5rem;
    font-weight: 300;
}

.wrapper {
    height: 100%;
    width: 85%;
    margin: 0 auto;
    position: relative;
}
.results {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.protocols {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 10px;
}
.protocols table {
    border-collapse: collapse;
}

.internal-protocols-list {
    background: var(--extra-bright-grey);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    overflow-x: auto;
    min-height: 55vh;
}

.internal-protocols-list > span {
    translate: 0 calc(50% - 50px);
}

.list {
    width: 100%;
    border-radius: 10px;
}

.list thead {
    text-align: left;
    border-radius: 0 10px 10px 0;
}
.list td,
.list th {
    border: 1px solid #555;
}
.list td {
    padding: 15px 20px;
}
.list td a {
    cursor: pointer;
}
.list td:last-child {
    text-align: center;
}
.list th {
    padding: 20px;
    border: none;
    min-width: 150px;
    font-weight: normal;
    font-size: 1.125rem;
    cursor: pointer;
    background: #555;
}
.list tr {
    background: #333;
}
.inline-button {
    margin: 0;
}
.inline-button svg {
    margin-right: 5px;
}

.edit-button {
    position: absolute;
    left: -50px;
}

.status-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-size: 1rem;
    cursor: pointer;
    background: var(--bright-grey);
    border: 1px solid var(--extra-bright-grey);
    height: 48px;
    color: var(--main-white);
    padding: 0 20px;
}
.status-button svg,
.add svg {
    height: 20px;
    translate: 0 -1px;
}

.status-button:nth-child(1) {
    border-radius: 10px 0 0 10px;
}

.status-button:nth-child(4) {
    border-radius: 0 10px 10px 0;
}

.active-button {
    background: #666;
}

.tabs {
    width: 100%;
    position: relative;
    margin-bottom: 30px;
}

.add {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 0;
}
.preview {
    padding: 40px 0;
    min-width: 500px;
    max-width: 800px;
}
