.info {
    width: 80%;
    margin: 0 auto;
}
.info form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.info:select::after {
    content: '';
    padding: 20px;
}
.buttons {
    margin-top: 30px;
}
.delete {
    color: var(--main-red);
}
