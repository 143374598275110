li {
    margin: 0;
}
.item {
    display: flex;
    flex-direction: column;
    gap: 2px;
    font-size: var(--nav-text-size);
    font-weight: 400;
    line-height: 1.5rem;
    position: relative;
}
.item svg {
    margin-right: var(--nav-spacing);
    width: var(--nav-icon-size);
    height: var(--nav-icon-size);
}
.item div {
    display: flex;
    align-items: center;
}
.item ul {
    display: flex;
    flex-direction: column;
    gap: 2px;
}
.item a {
    margin: 0 15px 0 20px;
    display: flex;
    align-items: center;
    padding: var(--nav-item-padding) 0;
    padding-left: var(--nav-item-padding);
    border-radius: 10px;
}
.item .is-active {
    background-color: var(--extra-bright-grey);
}
.item a:hover {
    background-color: var(--transparent-bright-grey);
}
.item .is-active:hover {
    background-color: var(--extra-bright-grey);
}

.item .inactive {
    background-color: transparent;
}
.notif {
    right: 30px;
    top: 38%;
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--main-blue);
}
