.wrapper {
    display: flex;
    flex-flow: column;
    gap: 20px;
}
.info {
    display: flex;
    flex-flow: column;
    gap: 10px;
}
.model {
    display: flex;
    align-items: center;
    gap: 10px;
}
.newBadge {
    position: absolute;
    transform: translateY(calc(-100% - 10px));
}
.versions {
    color: #51a6d9;
    display: flex;
    align-items: center;
    gap: 10px;
}
.select {
    background: transparent;
    color: white;
    border: 1px solid white;
    padding: 5px;
    border-radius: 5px;
}
.actions {
    display: flex;
    gap: 20px;
    flex-flow: row wrap;
}
.actions button {
    margin: 0;
}
