.wrapper {
    padding: 30px;
    border-radius: 15px;
    border: 1px solid #2e2e2e;
    background: #181818;
}

.wrapper select {
    width: 100%;
    background: #444;
    color: #fff;
}

.container {
    display: flex;
    gap: 50px;
}

.container > div {
    flex: 1;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.wrapper label {
    font-size: 0.85rem;
    margin-bottom: 10px;
}

.selectFile {
    width: 100%;
    background: #444;
    color: #fff;
    line-height: 150%;
}
