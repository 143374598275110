.selectView {
    display: flex;
    flex-direction: row;
}
.item {
    display: flex;
    flex-direction: row;
}
.item input {
    margin: 5px 2px;
}
.button {
    /* margin-top: 0; */
}
.tableRow {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between;
    align-items: center; */
}
.corner {
    width: 190px;
}
