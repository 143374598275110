.ring.small {
    width: 25px;
    height: 25px;
    margin: -5px 0 -6px 0;
}
.ring.large {
    width: 100px;
    height: 100px;
    /* margin: -5px 0 -6px 0; */
}
.ring {
    display: inline-block;
    position: relative;
}
.ring.large div {
    width: 80px;
    height: 80px;
    margin: 10px;
    border: 10px solid #fff;
    border-color: #fff transparent transparent transparent;
}
.ring.small div {
    width: 19px;
    height: 19px;
    margin: 3px;
    border: 3px solid #fff;
    border-color: #fff transparent transparent transparent;
}
.ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    border-radius: 50%;
    animation: ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
