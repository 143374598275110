.implant {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 25vh auto;
    gap: 40px;
}
.info {
    grid-column: 1 / 2;
    grid-row: 1/2;
}
.image {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
}
.comments {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}
.customField {
    margin: 30px 0;
}
.deptTools,
.deptActions {
    display: flex;
    flex-flow: column;
    gap: 20px;
}
.deptActions > button {
    width: 100%;
    justify-content: center;
    margin: 0;
}
@media screen and (max-width: 1400px) {
    .implant {
        grid-template-rows: 30vh auto;
    }
}
